import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { TypeAnimation } from "react-type-animation";
// import AOS from "aos";
// import "aos/dist/aos.css";
import "./style.scss";

declare const window: Window & typeof globalThis & { Jupiter: any; opera: any };

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const HomePage = () => {
  useEffect(() => {
    if (window.Jupiter) {
      window.Jupiter.init({
        displayMode: "integrated",
        integratedTargetId: "integrated-terminal",
        endpoint:
          "https://green-warmhearted-putty.solana-mainnet.quiknode.pro/530bd2a41cc356062e5cd8b0e69d1fc9bda27d30",
        formProps: {
          fixedOutputMint: true,
          initialOutputMint: "7Akh51JvZDvEi9a5KLHkkEfnajJzqRmnK2jVUvW1XRPA",
        },
      });
    }
  }, []);

  // AOS.init({
  //   duration: 1000, // Animation duration in milliseconds
  //   easing: "ease-in-out", // Easing function for animations
  //   once: true, // Whether animation should happen only once
  //   mirror: false, // Whether elements should animate out while scrolling past them
  // });
  const [bgColor, setBgColor] = useState(getRandomColor());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBgColor(getRandomColor());
    }, 200); // Change background color every 2 seconds

    return () => clearInterval(intervalId); // Clean up on component unmount
  }, []);
  return (
    <div style={{ backgroundColor: bgColor }}>
      <header className="header">
        <div className="container-row">
          <div className="main-menu">
            <ul className="list-menu">
              <li>
                <a href="#about">ABOUT</a>
              </li>
              <li>
                <a href="#SUK">$SUK</a>
              </li>

              <li>
                <a href="#buy">Buy</a>
              </li>
              <li>
                <a href="#chart">Chart</a>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <section className="section-about" id="about">
        <div className="wrap-about">
          {/* <div className="box-img">
            <img src="../images/vvv.gif" alt="" />
          </div> */}
          <a href="#">
            <img src="../images/main-logo.png" alt="" />
          </a>
          <div className="title">
            <TypeAnimation
              style={{
                whiteSpace: "pre-line",
                height: "195px",
                display: "block",
              }}
              sequence={[
                "Sasuke",
                1000,
                `Ketamine`,
                1000,
                "Sasuke ",
                1000,
                `Ketamine`,
                1000,
              ]}
              wrapper="span"
              speed={30}
              repeat={Infinity}
            />
          </div>
          <div
            className="list-social"
            // data-aos="zoom-in"
            // data-aos-duration="1000"
          >
            <div className="item">
              <a href="https://x.com/sasuketamine" target="blank">
                <img src="../images/s-1.png" alt="" />
              </a>
            </div>
            <div className="item">
              <a href="https://t.me/+whMEqIHnxvQ3MTZl" target="blank">
                <img src="../images/s-2.png" alt="" />
              </a>
            </div>
            <div className="item">
              <img src="../images/s-3.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="section-pool-info">
        <div className="container-row">
          <div className="wrap-pool-info">
            <div className="title">CONTACT ADDRESS</div>
            <div className="row-contract">
              Contract: HGTVhcwsqmjJo8wBppGmnVy5c7vv1xoWGq62Rdzypump
              <div className="icon">
                <img src="../images/copy.png" alt="" />
              </div>
            </div>
            <a
              href="https://pump.fun/HGTVhcwsqmjJo8wBppGmnVy5c7vv1xoWGq62Rdzypump"
              className="row-buy-SUK"
            >
              <button className="btn-buy">Buy $SUK</button>
            </a>
          </div>
        </div>
      </section>
      {/* <section className="section-marquue">
        <Marquee>
          <div className="list-marquee">
            <div className="item">
              <img src="../images/SUK.png" alt="" />
            </div>
            <div className="item">
              <img src="../images/main-logo.png" alt="" />
            </div>
            <div className="item">
              <img src="../images/SUK.png" alt="" />
            </div>
            <div className="item">
              <img src="../images/main-logo.png" alt="" />
            </div>
            <div className="item">
              <img src="../images/SUK.png" alt="" />
            </div>
            <div className="item">
              <img src="../images/main-logo.png" alt="" />
            </div>
            <div className="item">
              <img src="../images/SUK.png" alt="" />
            </div>
            <div className="item">
              <img src="../images/main-logo.png" alt="" />
            </div>
            <div className="item">
              <img src="../images/SUK.png" alt="" />
            </div>
            <div className="item">
              <img src="../images/main-logo.png" alt="" />
            </div>
            <div className="item">
              <img src="../images/SUK.png" alt="" />
            </div>
            <div className="item">
              <img src="../images/main-logo.png" alt="" />
            </div>
          </div>
        </Marquee>
      </section> */}
      {/* <section className="section-human-word" id="SUK">
        <div className="container-row">
          <div className="wrap-human">
            <div className="box-img">
              <img src="../images/aaa.gif" alt="" />
            </div>
            <div
              className="guide-human"
              
            >
              <div className="title">About SUK</div>
              <div className="desc">
                It’s just a <span>The SUK of Wall Street</span>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="section-swap">
                <div className="container-row">
                    <div className="wrap-swap"  >
                        <div className="title">
                            <img src="../images/title.png" alt="" />
                        </div>
                        <div className="box-wrap-swap">
                            <div id="integrated-terminal" style={{ width: '400px' }}></div>
                        </div>
                    </div>
                </div>
            </section> */}
      <section className="section-buy" id="buy">
        <div className="container-row">
          <div className="wrap-buy">
            <div className="title">How to buy?</div>
            <div className="group-buy">
              {/* <div
                className="item"
                 
              >
                <div className="icon-buy">
                  <img src="../images/b-1.png" alt="" />
                </div>
                <div className="text-buy">
                  <div className="txt">Create a Wallet</div>
                  <div className="desc">
                    Download and install phantom on the appstore, or,
                    alternatively if you’re on desktop download and install the
                    browser extension
                  </div>
                  <button className="btn-get">
                    <a
                      href="https://chromewebstore.google.com/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa"
                      target="blank"
                    >
                      Get Phantom
                    </a>
                  </button>
                </div>
              </div> */}
              {/* <div
                className="item"
                 
              >
                <div className="icon-buy">
                  <img src="../images/b-2.png" alt="" />
                </div>
                <div className="text-buy">
                  <div className="txt">Secure your SOL</div>
                  <div className="desc">
                    Now all you’ve got to do is buy your SOL in the Phantom App,
                    or, alternatively you can use an exchange and deposit using
                    your wallet address
                  </div>
                </div>
              </div> */}
              {/* <div
                className="item"
                 
              >
                <div className="icon-buy">
                  <img src="../images/b-3.png" alt="" />
                </div>
                <div className="text-buy">
                  <div className="txt">Buy some $SUK</div>
                  <div className="desc">
                    Now go to Raydium and paste the $SUK contract address to
                    swap your SOL
                  </div>
                </div>
              </div> */}
              <div className="item">
                <div className="icon-buy">
                  <img src="../images/logo.jpg" alt="" />
                </div>
                <div className="text-buy">
                  <div className="txt">Add to PUMP FUN</div>
                  <div className="desc">
                    Now you’re all set! Welcome aboard the next rocket to the
                    moon, just import the contract address to view you’re
                    holdings and you can track your earnings.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-tokenomic">
        {/* <div className="m-images">
          <img src="../images/m-2.png" alt="" />
        </div> */}
        {/* <div className="m-images-s">
          <img src="../images/m-1.png" alt="" />
        </div> */}
        <div className="container-row">
          <div className="wrap-tokenomic" id="chart">
            <div className="title">Tokenomics</div>
            <div className="list-info-burn">
              <div className="item">
                <div className="box-img">
                  <img src="../images/main-logo.png" alt="" />
                </div>
                <div className="text">LP BURNT</div>
              </div>
              <div className="item">
                <div className="box-img">
                  <img src="../images/main-logo.png" alt="" />
                </div>
                <div className="text">MINT REVOKED</div>
              </div>
              <div className="item">
                <div className="box-img">
                  <img src="../images/main-logo.png" alt="" />
                </div>
                <div className="text">0% TAX</div>
              </div>
            </div>
            <div className="address-sc">
              <div className="row-contract">
                Contract: HGTVhcwsqmjJo8wBppGmnVy5c7vv1xoWGq62Rdzypump
                <div className="icon">
                  <img src="../images/copy.png" alt="" />
                </div>
              </div>
              <a
                href="https://pump.fun/HGTVhcwsqmjJo8wBppGmnVy5c7vv1xoWGq62Rdzypump"
                className="row-buy-SUK"
              >
                <button className="btn-buy">Buy $SUK</button>
              </a>
            </div>
            <div className="title res">Toltal Supply:</div>
            <div className="number-total">1,000,000,000 SUK</div>
            <div className="number-total">
              Sasuke made 1,000,000,000 ketamine but he's a greedy son of a
              bitch and wants to keep them all for himself
            </div>
            <div className="title mar-b-60">100% Burner LP.</div>
            <div className="title">Follow Us On Social Media</div>
            <div className="list-social">
              <div className="item">
                <a href="https://x.com/sasuketamine" target="blank">
                  <img src="../images/s-1.png" alt="" />
                </a>
              </div>
              <div className="item">
                <a href="https://t.me/+whMEqIHnxvQ3MTZl" target="blank">
                  <img src="../images/s-2.png" alt="" />
                </a>
              </div>
              <div className="item">
                <img src="../images/s-3.png" alt="" />
              </div>
            </div>
            <div className="info-SUK">
              <div className="txt-name">$SUK</div>
              <div className="desc-name">
                It’s just a <span>The SUK of Wall Street</span>
              </div>
              <a
                href="https://pump.fun/HGTVhcwsqmjJo8wBppGmnVy5c7vv1xoWGq62Rdzypump"
                className="row-buy-SUK"
              >
                <button className="btn-buy">Buy $SUK</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer">
        <div className="container-row">
          <div className="wrap-footer">
            <div className="list-social">
              <div className="item">
                <a href="https://x.com/sasuketamine" target="blank">
                  <img src="../images/s-1.png" alt="" />
                </a>
              </div>
              <div className="item">
                <a href="https://t.me/+whMEqIHnxvQ3MTZl" target="blank">
                  <img src="../images/s-2.png" alt="" />
                </a>
              </div>
              <div className="item">
                <img src="../images/s-3.png" alt="" />
              </div>
            </div>
            <div className="copy-right">© 2024 $SUK - ALL RIGHTS RESERVED</div>
          </div>
        </div>
        {/* <div className="box-img">
          <img src="../images/aaa.gif" alt="" />
        </div> */}
      </footer>
    </div>
  );
};
export default HomePage;
